<template>
    <Spinner v-if="!data" />
    <div class="row" v-if="data">
        <h1 class="text-center text-white">THRESHOLDS</h1>
        <b-row class="p-5">
            <b-col lg="3">
                <b-dropdown split :text="handleThresholdFilter()">
                    <b-dropdown-item href="#" @click.prevent="handleIsAll">All</b-dropdown-item>
                    <b-dropdown-item href="#" @click.prevent="handleIsConnection">Connection Limit</b-dropdown-item>
                    <b-dropdown-item href="#" @click.prevent="handleIsPacket">Packet Tracking</b-dropdown-item>
                    <b-dropdown-item href="#" @click.prevent="handleIsVolume">Traffic Volume</b-dropdown-item>
                </b-dropdown>
            </b-col>

            <b-col lg="3">
                <b-button variant="primary" class="mr-2" size="sm" @click="modalShow = !modalShow">
                    Add Threshold
                    <font-awesome-icon icon="pencil" />
                </b-button>
            </b-col>

            <b-col lg="3" v-if="deleteSelected ||
                deletedConnectionList.length > 0 ||
                deletedPacketList.length > 0
                ">
                <b-button variant="danger" class="mr-2" size="sm" @click="enableMultiDelete = true">
                    Delete Selected
                    <font-awesome-icon icon="trash" />
                </b-button>
            </b-col>

        </b-row>

        <div>
            <b-modal v-model="enableMultiDelete" size="sm" title="Delete Threshold" hide-backdrop ok-title="Delete" @ok="deletedConnectionList.forEach((item) => {
                    deleteThreshold(item.id, item.type)
                });
            deletedPacketList.forEach((item) => {
                deleteThreshold(item.id, item.type)
            })
                                                                                                            ">
                Are you sure you want to delete these thresholds?
            </b-modal>
        </div>

        <div>
            <b-modal v-model=" enableDelete " size="sm" title="Delete Threshold" hide-backdrop ok-title="Delete"
                @ok=" deleteThreshold(singleDeleteData.id, singleDeleteData.type) ">
                Are you sure you want to delete this threshold?
            </b-modal>
        </div>


        <div>

        </div>

        <div>
            <b-modal v-model=" modalShow " size="lg" :title="enableEdit ? 'Edit Threshold' : 'Add Threshold'" :ok-title="enableEdit ? 'Update' : 'Add'" hide-backdrop @hidden="onHideAddEditModal"
                @ok=" enableEdit ?  saveSettings(singleEditData.id) : thresholdRequest() ">
                <b-form>
                    <div class="card bg-secondary border-0 w-100">
                        <b-row class="setting-edit">
                            <div style="width: 100%;">
                                <h3 class="my-3 mx-3">General</h3>
                                <b-row class="p-3">
                                    <div lg="3">
                                        <b-form-group label="Threshold Type" label-for="varThresholdType" class="mb-3">
                                            <div>
                                                <b-dropdown :disabled="enableEdit" split :text="handleSettingType()">
                                                    <b-dropdown-item href="#"
                                                        @click.prevent=" handleReqThreshold('CONNECTION') ">Connection
                                                        Limit</b-dropdown-item>
                                                    <b-dropdown-item href="#"
                                                        @click.prevent=" handleReqThreshold('PACKET') ">Packet
                                                        Tracking</b-dropdown-item>
                                                    <b-dropdown-item href="#"
                                                        @click.prevent=" handleReqThreshold('TRAFFIC_VOLUME') ">Traffic
                                                        Volume</b-dropdown-item>
                                                </b-dropdown>
                                            </div>
                                        </b-form-group>
                                    </div>
                                </b-row>
                                <b-row class="p-3">
                                    <b-col>
                                        <b-form-group label="Destination Prefix" label-for="destinationPrefix" class="mb-3">
                                            <b-form-input id="destinationPrefix" name="destinationPrefix" type="text"
                                                v-model=" setting.destinationPrefix " placeholder="0.0.0.0/00" required />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row class="p-3">
                                    <div lg="3">
                                        <b-form-group label="Traffic Type" label-for="trafficType" class="mb-3">
                                            <div>
                                                <b-dropdown split :text="
                                                    setting.trafficType == '' ?
                                                        'Select traffic type' : setting.trafficType
                                                ">
                                                    <b-dropdown-item href="#"
                                                        @click.prevent=" handleReqTraffic('UDP') ">UDP</b-dropdown-item>
                                                    <b-dropdown-item href="#"
                                                        @click.prevent=" handleReqTraffic('TCP') ">TCP</b-dropdown-item>
                                                    <b-dropdown-item href="#"
                                                        @click.prevent=" handleReqTraffic('ICMP') ">ICMP</b-dropdown-item>
                                                    <b-dropdown-item href="#"
                                                        @click.prevent=" handleReqTraffic('Other') ">Other</b-dropdown-item>
                                                </b-dropdown>
                                            </div>
                                        </b-form-group>
                                    </div>
                                </b-row>
                                <div lg="3" class="p-3" v-if=" setting.type =='PACKET' ">
                                    <b-form-group id="minimumPacket" label="Track Minimum Packet Length"
                                        label-for="minimumPacket">
                                        <b-form-input class="w-100" id="minimumPacket" v-model=" setting.minPacketLength "
                                            name="minimumPacket" type="number" placeholder="0 - 1000000"
                                            required></b-form-input>
                                    </b-form-group>
                                </div>
                                <div lg="3" class="p-3" v-if=" setting.type =='PACKET' ">
                                    <b-form-group id="maximumPacket" label="Track Maximum Packet Length"
                                        label-for="maximumPacket">
                                        <b-form-input class="w-100" id="maximumPacket" v-model=" setting.maxPacketLength "
                                            name="maximumPacket" type="number" placeholder="0 - 1000000"
                                            required></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="d-flex flex-row mx-3" v-if=" setting.type =='PACKET' ">
                                    <p>Block Packet Length</p>
                                    &nbsp;
                                    &nbsp;
                                    <b-form-checkbox name="check-button" v-model=" setting.blockPacketLength " />
                                </div>
                        
                                <div v-if="setting.type != 'TRAFFIC_VOLUME'">
                                    <b-row class="p-3">
                                        <b-col>
                                            <b-form-group id="connectionRate" label="Connection Rate"
                                                label-for="connectionRate">
                                                <b-form-input class="w-100" id="connectionRate" name="connectionRate"
                                                    v-model=" setting.connectionRate " type="number" placeholder="1 - 1000000"
                                                    required></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-row class="p-3">
                                        <b-col>
                                            <b-form-group id="connectionInterval" label="Connection Interval (in seconds)"
                                                label-for="connectionInterval">
                                                <b-form-input class="w-100" id="connectionInterval"
                                                    v-model=" setting.connectionInterval " name="connectionInterval" type="number"
                                                    placeholder="1 - 1000000" required></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </div>

                                <div v-if="setting.type == 'TRAFFIC_VOLUME'">
                                    <b-row class="p-3">
                                        <b-col>
                                            <b-form-group id="volume" label="Volume (MB)"
                                                label-for="volume">
                                                <b-form-input class="w-100" id="volume" name="volume"
                                                    v-model=" setting.volume " type="number" placeholder="1 - 1000000"
                                                    required></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-row class="p-3">
                                        <b-col>
                                            <b-form-group id="volumeInterval" label="Volume Interval (in seconds)"
                                                label-for="volumeInterval">
                                                <b-form-input class="w-100" id="volumeInterval"
                                                    v-model=" setting.volumeInterval " name="volumeInterval" type="number"
                                                    placeholder="10 - 1000000" min="10" required></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </div>

                                <div class="w-100 p-3" v-if=" setting.type =='CONNECTION' ">
                                    <b-form-group id="sourcePorts" :label="`Source ${setting.trafficType} Ports`" label-for="sourcePorts">
                                        <b-form-input id="sourcePorts" name="sourcePorts" type="text"
                                            v-model=" setting.sourcePorts " placeholder="comma-seperated numbers"
                                            required></b-form-input>
                                    </b-form-group>
                                    <b-form-group id="destinationPort" :label="`Destination ${setting.trafficType} Ports`"
                                        label-for="destinationPort">
                                        <b-form-input id="destinationPort" name="destinationPort" type="text"
                                            v-model=" setting.destinationPorts " placeholder="comma-seperated numbers"
                                            required></b-form-input>
                                    </b-form-group>
                                    <b-form-group v-if=" setting.type =='CONNECTION'" id="includeDetectedPortsInActiveRule" label="" label-for="includeDetectedPortsInActiveRule">
                                        <b-row>
                                            <b-col>
                                            <p style="font-size: large">
                                                Include all detected ports in Active Rule
                                            </p>
                                            </b-col>
                                            <b-col>
                                            <b-form-checkbox
                                                switch
                                                name="check-button"
                                                v-model="setting.includeDetectedPortsInActiveRule" 
                                            />
                                            </b-col>
                                        </b-row>
                                    </b-form-group>
                                </div>

                                <h3 class="p-3">Static Actions</h3>
                                <div class="w-100 p-3" v-show="options.length > 0">
                                    <Multiselect 
                                        :searchable="true"
                                        :required="true"
                                        mode="tags"
                                        placeholder="Type to search"
                                        v-model="setting.staticActionIds" 
                                        :options="options"
                                    />
                                </div>

                                <h3 class="p-3">Time Limit</h3>

                                <div class="w-100 p-3">
                                    <b-form-group id="timeLimit" label="Time Limit in seconds" label-for="timeLimit">
                                        <b-form-input id="timeLimit" name="timeLimit" type="text"
                                            v-model=" setting.timeLimit " placeholder="0"
                                            required></b-form-input>
                                    </b-form-group>
                                </div>

                                <h3 class="p-3">Additional Filters</h3>

                                <div class="w-100 p-3">
                                    <b-form-group id="additionalFilterEnabled" label="" label-for="additionalFilterEnabled">
                                        <b-row>
                                            <b-col>
                                            <p style="font-size: large">
                                                {{setting.additionalFilterEnabled ? 'Hide ' : 'Show ' }} Additional Filters
                                            </p>
                                            </b-col>
                                            <b-col>
                                            <b-form-checkbox
                                                switch
                                                name="check-button"
                                                v-model="setting.additionalFilterEnabled" 
                                            />
                                            </b-col>
                                        </b-row>
                                    </b-form-group>
                                    <b-form-group v-if="setting.additionalFilterEnabled" id="sourcePrefix" label="Source Prefix" label-for="sourcePrefix">
                                        <b-form-input id="sourcePrefix" name="sourcePrefix" type="text"
                                            v-model=" setting.additionalFilter.sourcePrefix " placeholder="0.0.0.0/00"
                                            required></b-form-input>
                                    </b-form-group>
                                    <b-form-group v-if="setting.additionalFilterEnabled" id="destinationPrefix" label="Destination Prefix"
                                        label-for="destinationPrefix">
                                        <b-form-input id="destinationPrefix" name="destinationPrefix" type="text"
                                            v-model=" setting.additionalFilter.destinationPrefix "
                                            placeholder="comma-seperated numbers" required></b-form-input>
                                    </b-form-group>
                                    <b-form-group v-if="setting.additionalFilterEnabled" id="icmpType" label="ICMP Type" label-for="icmpType">
                                        <b-form-input id="icmpType" name="icmpType" type="text"
                                            v-model=" setting.additionalFilter.ICMPType " placeholder="" required></b-form-input>
                                    </b-form-group>
                                    <b-form-group v-if="setting.additionalFilterEnabled" id="icmpCode" label="ICMP Code" label-for="icmpCode">
                                        <b-form-input id="icmpCode" name="icmpCode" type="number"
                                            v-model=" setting.additionalFilter.ICMPCode " placeholder="" required></b-form-input>
                                    </b-form-group>
                                    <b-form-group v-if="setting.additionalFilterEnabled" id="tcpFLags" label="TCP Flags" label-for="tcpFLags">
                                        <b-form-input id="tcpFLags" name="tcpFLags" type="text"
                                            v-model=" setting.additionalFilter.TCPflags " placeholder="" required></b-form-input>
                                    </b-form-group>
                                    <b-form-group v-if="setting.additionalFilterEnabled" id="dscp" label="DSCP" label-for="dscp">
                                        <b-form-input id="dscp" name="dscp" type="text" v-model=" setting.additionalFilter.DSCP "
                                            placeholder="" required></b-form-input>
                                    </b-form-group>


                                </div>

                                <h3 class="mx-3">Status</h3>

                                <div class="w-100 p-3">
                                    <b-form-checkbox 
                                        label="Enable Threshold" 
                                        name="check-button" 
                                        v-model=" setting.enabled " 
                                    >
                                        Enable Threshold
                                    </b-form-checkbox>
                                </div>

                            </div>
                        </b-row>

                    </div>
                </b-form>

            </b-modal>
        </div>

        <b-row class="p-3">
            <div class="col-8 w-100 table-responsive-lg" v-if="
                packetThreshold.length > 0 ||
                    connectionThreshold.length > 0 ||
                    trafficVolumeThreshold.length > 0
            ">
                <table class="table table-dark">
                    <thead class="thead-dark">
                        <tr class="text-center">
                            <th scope="col">
                            <td>
                                <b-form-checkbox name="check-button" v-model=" deleteSelected "
                                    @change=" bulkDeleteThreshold " />
                            </td>
                            </th>
                            <th scope="col">Id</th>
                            <th scope="col">Priority</th>
                            <th scope="col">Dest Prefix</th>
                            <th scope="col">Type</th>
                            <th scope="col">Connection Interval</th>
                            <th scope="col">Connection Rate(s)</th>
                            <th scope="col">Min Packet Length / Volume</th>
                            <th scope="col">Max Packet Length / Volume Interval</th>
                            <th scope="col">Additional Filters</th>
                            <th scope="col">Enabled</th>
                            <th scope="col">Dest Ports</th>
                            <th scope="col">Source Ports</th>
                            <th scope="col">Traffic</th>
                            <th scope="col">Updated</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <draggable v-if=" varThresholdType == 'CONNECTION' || varThresholdType == '' "
                        v-model=" connectionThreshold " tag="tbody" @change=" updateThresholdPriority " :disabled="isMobile">
                        <template #item=" { element } ">
                            <tr class="text-center">
                                <td>
                                    <b-form-checkbox name="check-button" v-model=" element.selected "
                                        @change=" updateDeleteConnectionList(element.id) " />
                                </td>
                                <td class="text-white" scope="row">{{ element.id }}</td>
                                <td class="text-white" scope="row">{{ element.priority }}</td>
                                <td class="text-white">{{ element.destinationPrefix }}</td>
                                <td class="text-white">Connection</td>
                                <td class="text-white">{{ element.connectionInterval }}</td>
                                <td class="text-white">{{ element.connectionRate }}</td>
                                <td class="text-white">{{ element.minPacketLength }}</td>
                                <td class="text-white">{{ element.maxPacketLength }}</td>
                                <td class="text-white">{{ element.additionalFilter == null ? 'False' : 'True' }}</td>
                                <td class="text-white">{{ element.enabled == true ? 'True' : 'False' }}</td>
                                <td class="text-white">{{ element.destinationPorts  == null ? 'False' : 'True' }}</td>
                                <td class="text-white">{{ element.sourcePorts  == null ? 'False' : 'True' }}</td>
                                <td class="text-white">{{ element.trafficType}}</td>
                                <td class="text-white" v-if=" element ">{{ getDateString(new
                                    Date().toISOString(element.updatedAt)) }}</td>
                                <td class="text-white d-flex">
                                    <b-button variant="primary" class="mx-auto" size="sm"
                                        @click.prevent=" enableEditing(element.id, 'CONNECTION') ">
                                        <font-awesome-icon icon="pencil" />
                                    </b-button> &nbsp;
                                    <b-button variant="danger" class="mx-2" size="sm"
                                        @click=" enableDeletion(element.id, 'CONNECTION') ">
                                        <font-awesome-icon icon="trash" />
                                    </b-button>
                                </td>
                            </tr>
                        </template>
                    </draggable>
                    <draggable v-if=" varThresholdType == 'PACKET' || varThresholdType == '' " v-model=" packetThreshold "
                        tag="tbody" @change=" updateThresholdPriority " :disabled="isMobile">
                        <template #item=" { element } ">
                            <tr class="text-center">
                                <td>
                                    <b-form-checkbox name="check-button" v-model=" element.selected "
                                        @change=" updateDeletePacketList(element.id) " />
                                </td>
                                <td class="text-white" scope="row">{{ element.id }}</td>
                                <td class="text-white" scope="row">{{ element.priority }}</td>
                                <td class="text-white">{{ element.destinationPrefix }}</td>
                                <td class="text-white">Packet</td>
                                <td class="text-white">{{ element.connectionInterval }}</td>
                                <td class="text-white">{{ element.connectionRate }}</td>
                                <td class="text-white">{{ element.minPacketLength }}</td>
                                <td class="text-white">{{ element.maxPacketLength }}</td>
                                <td class="text-white">{{ element.additionalFilter == null ? 'False' : 'True' }}</td>
                                <td class="text-white">{{ element.enabled == true ? 'True' : 'False' }}</td>
                                <td class="text-white">{{ element.destinationPorts == null ? 'False' : 'True' }}</td>
                                <td class="text-white">{{ element.sourcePorts  == null ? 'False' : 'True' }}</td>
                                <td class="text-white">{{ element.trafficType }}</td>
                                <td class="text-white">{{ getDateString(new Date().toISOString(element.updatedAt)) }}</td>
                                <td class="text-white d-flex">
                                    <b-button variant="primary" size="sm" class="mx-auto"
                                        @click.prevent=" enableEditing(element.id, 'PACKET') ">
                                        <font-awesome-icon icon="pencil" />
                                    </b-button> &nbsp;
                                    <b-button variant="danger" size="sm" class="mx-2"
                                        @click=" enableDeletion(element.id, 'PACKET') ">
                                        <font-awesome-icon icon="trash" />
                                    </b-button>
                                </td>
                            </tr>
                        </template>
                    </draggable>
                    <draggable v-if=" varThresholdType == 'TRAFFIC_VOLUME' || varThresholdType == '' " v-model=" trafficVolumeThreshold "
                        tag="tbody" @change=" updateThresholdPriority " :disabled="isMobile">
                        <template #item=" { element } ">
                            <tr class="text-center">
                                <td>
                                    <b-form-checkbox name="check-button" v-model=" element.selected "
                                        @change=" updateDeleteVolumeList(element.id) " />
                                </td>
                                <td class="text-white" scope="row">{{ element.id }}</td>
                                <td class="text-white" scope="row">{{ element.priority }}</td>
                                <td class="text-white">{{ element.destinationPrefix }}</td>
                                <td class="text-white">Volume</td>
                                <td class="text-white">{{ 'None' }}</td>
                                <td class="text-white">{{ 'None' }}</td>
                                <td class="text-white">{{ element?.volume }} MB</td>
                                <td class="text-white">{{ element?.volumeInterval }} seconds</td>
                                <td class="text-white">{{ element.additionalFilter == null ? 'False' : 'True' }}</td>
                                <td class="text-white">{{ element.enabled == true ? 'True' : 'False' }}</td>
                                <td class="text-white">{{ element?.destinationPorts == null ? 'False' : 'True' }}</td>
                                <td class="text-white">{{ element?.sourcePorts  == null ? 'False' : 'True' }}</td>
                                <td class="text-white">{{ element.trafficType }}</td>
                                <td class="text-white">{{ getDateString(new Date().toISOString(element.updatedAt)) }}</td>
                                <td class="text-white d-flex">
                                    <b-button variant="primary" size="sm" class="mx-auto"
                                        @click.prevent=" enableEditing(element.id, 'TRAFFIC_VOLUME') ">
                                        <font-awesome-icon icon="pencil" />
                                    </b-button> &nbsp;
                                    <b-button variant="danger" size="sm" class="mx-2"
                                        @click=" enableDeletion(element.id, 'TRAFFIC_VOLUME') ">
                                        <font-awesome-icon icon="trash" />
                                    </b-button>
                                </td>
                            </tr>
                        </template>
                    </draggable>
                </table>
            </div>
        </b-row>
    </div>
</template>
<script>

import ThresholdService from '../../services/threshold.service';
import StaticActionsService from "../../services/static-actions.service";
import draggable from 'vuedraggable';
import Spinner from "../../components/spinner/spinner.vue";
import { DateTime } from "luxon";
import "vue3-toastify/dist/index.css";
import { notify } from "../../helpers/index";
// import { BFormSelect } from 'bootstrap-vue-3';
import Multiselect from '@vueform/multiselect';

const initialSettings = {
    type: "CONNECTION",
    trafficType: "UDP",
    connectionRate: 0,
    connectionInterval: 0,
    minPacketLength: "",
    maxPacketLength: "",
    packetLengthToBlock:  0,
    blockPacketLength: false,
    sourcePorts: "",
    destinationPrefix: "",
    destinationPorts: "",
    volume: 0,
    volumeInterval: 0,
    enabled: false,
    includeDetectedPortsInActiveRule: false,
    additionalFilter: {
        sourcePrefix: "",
        destinationPrefix: "",
        ipProtocol: "",
        sourcePort: "",
        destinationPort: "",
        ICMPType: "",
        ICMPCode: "",
        TCPflags: "",
        packetLength: "",
        DSCP: ""
    },
    additionalFilterEnabled: false,
    timeLimit: 0,
    staticActionIds: [],
    updatedAt: ""
};

export default {
    name: "Threshold",
    components: {
        draggable,
        Spinner,
        // BFormSelect,
        Multiselect
    },
    data() {
        return {
            loaded: false,
            options: [{value: "", text: "Search or click here", disabled:true}],
            setting: initialSettings,
            modalShow: false,
            data: null,
            connectionThreshold: [],
            packetThreshold: [],
            trafficVolumeThreshold: [],
            varThresholdType: '',
            deleteSelected: false,
            reqEnabled: false,
            minimumPacketLength: '',
            maximumPacketLength: '',
            dragging: false,
            deleteConnection: false,
            deletePacket: false,
            deletedPacketList: [],
            deletedVolumeList: [],
            deletedConnectionList: [],
            enableDelete: false,
            enableMultiDelete: false,
            singleDeleteData: {},
            singleEditData: {},
            enableEdit: false,
            isMobile: false 
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        thresholdType() {
            return this.$route.query.type;
        },
        thresholdTypeList() {
            return this.$route.params.thresholdTypeList;
        },
        thresholdId() {
            return this.$route.params.id;
        },
    },
    async mounted() {
        this.checkIfMobile();
        await this.getThresholds();
        await this.getAllStaticActions();
        this.$title = "Eagle"
        this.varThresholdType = ''
        this.setDefaultSelected()
        this.openEditingModalOnRoute()
    },
    methods: {
        checkIfMobile() {
      this.isMobile = /Mobi|Android/i.test(navigator.userAgent) || window.innerWidth <= 768;
    },
        async getAllStaticActions() {
            const url = "static-actions";
            
            try {
            const reply = await StaticActionsService.getAllStaticActions(url);
            if (reply.status == 200 && reply.data) {
                this.options = reply.data.map(d => ({
                    "value": d.id,
                    "label": `${d.id} - ${d.name}`
                    }));
                this.loaded = true;
            }
            }catch {
            notify("Session expired", "error");
            this.logOut();
            }
        },
        onHideAddEditModal () {
            this.enableEdit = false
            this.setting = initialSettings;
        },
        openEditingModalOnRoute() {
            if (this.thresholdId && this.thresholdTypeList) {
                this.enableEditing(this.thresholdId, this.thresholdTypeList)
            }
        },
        logOut() {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
        },
        handleSettingType() {
            let final = 'Select Threshold Type';
            switch (this.setting.type) {
                case 'PACKET':
                final =  'Packet Tracking';
                break;
                case 'CONNECTION':
                final =  'Connection Limit';
                break;
                case 'TRAFFIC_VOLUME':
                final =  'Volume Tracking';
                break;
                default:
                break;
            }
            return final;
        },
        handleThresholdFilter() {
            let final = 'All';
            switch (this.varThresholdType) {
                case 'PACKET':
                final =  'Packet Tracking';
                break;
                case 'CONNECTION':
                final =  'Connection Limit';
                break;
                case 'TRAFFIC_VOLUME':
                final =  'Volume Tracking';
                break;
                default:
                break;
            }
            return final;
        },
        enableDeletion(id, type) {
            this.enableDelete = true,
                this.singleDeleteData = {
                    id: id,
                    type: type
                }
        },
        enableEditing(id, type) {
            this.enableEdit = true,
            this.modalShow = true,
            this.singleEditData = {
                id: id,
                type: type
            }
            this.getThreshold(id, type)
        },
        async deleteThreshold(id, type) {
            const connectionUrl = `thresholds/${id}?type=${type}`;
            const response = await ThresholdService.deleteThreshold(connectionUrl);
            if (response.status == 200 && response.data) {
                if (type == 'CONNECTION') {
                    notify('Connection Limit Threshold Deleted Successfully')
                }
                if (type == 'PACKET') {
                    notify('Packet Tracking Threshold Deleted Successfully')
                }
                if (type == 'TRAFFIC_VOLUME') {
                    notify('Traffic Volume Threshold Deleted Successfully')
                }
                this.getThresholds()
            }
        },
        setDefaultSelected() {
            if (this.connectionThreshold) {
                this.connectionThreshold = this.connectionThreshold.map((item) => {
                    return {
                        ...item,
                        selected: false
                    };
                });
            }
            if (this.packetThreshold) {
                this.packetThreshold = this.packetThreshold.map((item) => {
                    return {
                        ...item,
                        selected: false
                    };
                });
            }
            if (this.trafficVolumeThreshold) {
                this.trafficVolumeThreshold = this.trafficVolumeThreshold.map((item) => {
                    return {
                        ...item,
                        selected: false
                    };
                });
            }
        },
        bulkDeleteThreshold() {
            if (this.deleteSelected) {
                this.connectionThreshold = this.connectionThreshold.map((item) => {
                    return {
                        ...item,
                        selected: true
                    };
                });
                this.connectionThreshold.forEach((item) => this.updateDeleteConnectionList(item.id));

                this.packetThreshold = this.packetThreshold.map((item) => {
                    return {
                        ...item,
                        selected: true
                    };
                });
                this.packetThreshold.forEach((item) => this.updateDeletePacketList(item.id))

                this.trafficVolumeThreshold = this.trafficVolumeThreshold.map((item) => {
                    return {
                        ...item,
                        selected: true
                    };
                });
                this.trafficVolumeThreshold.forEach((item) => this.updateDeleteVolumeList(item.id))
            }
            else {
                this.setDefaultSelected()
            }
        },
        updateDeleteConnectionList(id) {
            const existingItem = this.deletedConnectionList.find((item) => item.id === id);

            if (!existingItem) {
                const deletedItem = this.connectionThreshold.find((item) => item.id === id);
                if (deletedItem) {
                    this.deletedConnectionList.push(deletedItem);
                }
                this.deletedConnectionList = this.deletedConnectionList.map((item) => {
                    return {
                        ...item,
                        type: 'CONNECTION'
                    };
                });
            }

            if (existingItem) {
                const updatedList = this.deletedConnectionList.filter((item) => {
                    return item.id !== existingItem.id;
                });
                this.deletedConnectionList = updatedList;
            }
        },
        updateDeletePacketList(id) {
            const existingItem = this.deletedPacketList.find((item) => item.id === id);

            if (!existingItem) {
                const deletedItem = this.packetThreshold.find((item) => item.id === id);
                if (deletedItem) {
                    this.deletedPacketList.push(deletedItem);
                }
                this.deletedPacketList = this.deletedPacketList.map((item) => {
                    return {
                        ...item,
                        type: 'PACKET'
                    };
                });
            }

            if (existingItem) {
                const updatedList = this.deletedPacketList.filter((item) => {
                    return item.id !== existingItem.id;
                });
                this.deletedPacketList = updatedList;
            }
        },
        updateDeleteVolumeList(id) {
            const existingItem = this.deletedVolumeList.find((item) => item.id === id);

            if (!existingItem) {
                const deletedItem = this.trafficVolumeThreshold.find((item) => item.id === id);
                if (deletedItem) {
                    this.deletedVolumeList.push(deletedItem);
                }
                this.deletedVolumeList = this.deletedVolumeList.map((item) => {
                    return {
                        ...item,
                        type: 'TRAFFIC_VOLUME'
                    };
                });
            }

            if (existingItem) {
                const updatedList = this.deletedVolumeList.filter((item) => {
                    return item.id !== existingItem.id;
                });
                this.deletedVolumeList = updatedList;
            }
        },
        updateThresholdPriority() {
            const updatedConnectionThreshold = this.connectionThreshold.map((item, index) => {
                return {
                    ...item,
                    priority: index + 1
                };
            });

            this.connectionThreshold = updatedConnectionThreshold
            let cPriorityArr = this.connectionThreshold.map((item) => {
                return { id: item.id, priority: item.priority };
            });

            this.updateThresholdPriorityOnServer(cPriorityArr, 'CONNECTION')

            const updatedPacketThreshold = this.packetThreshold.map((item, index) => {
                return {
                    ...item,
                    priority: index + 1
                };
            });

            this.packetThreshold = updatedPacketThreshold
            let pPriorityArr = this.packetThreshold.map((item) => {
                return { id: item.id, priority: item.priority };
            });

            this.updateThresholdPriorityOnServer(pPriorityArr, 'CONNECTION')
        },
        async updateThresholdPriorityOnServer(item, type) {
            const url = `thresholds/priorities?type=${type}`;
            const reply = await ThresholdService.updateThreshold(url, JSON.stringify(item));
            if (reply.status == 200 && reply.data) {
                notify('Priority updated successfully')
            }
            if (reply.status == 500) {
                notify('Priority update failed')
            }
        },
        handleIsAll() {
            this.varThresholdType = '';
        },
        handleIsConnection() {
            this.varThresholdType = 'CONNECTION';
        },
        handleIsPacket() {
            this.varThresholdType = 'PACKET';
        },
        handleIsVolume() {
            this.varThresholdType = 'TRAFFIC_VOLUME';
        },
        handleReqThreshold(item) {
            this.setting.type = item;    
        },
        handleReqTraffic(item) {
            this.setting.trafficType = item;
        },
        async getThreshold(id, type) {
            const url = `thresholds/${id}?type=${type}`;
            const reply = await ThresholdService.getThreshold(url);
            if (reply.status == 200 && reply.data) {
                this.setting = reply.data;
                this.setting.type = type;
                this.setting.additionalFilterEnabled = !!this.setting.additionalFilter;
            }
            console.log(this.setting)
        },
        async getThresholds() {
            const connectionUrl = "thresholds?type=CONNECTION";
            const response = await ThresholdService.getThreshold(connectionUrl);
            const packetUrl = "thresholds?type=PACKET";
            const volumeUrl = "thresholds?type=TRAFFIC_VOLUME";
            try{
                const reply = await ThresholdService.getThreshold(packetUrl);
                this.data = response.data
                this.connectionThreshold = response.data.connectionThresholds
                this.connectionThreshold = this.connectionThreshold.sort((a, b) => {
                    return a.priority - b.priority;
                });
                this.packetThreshold = reply.data.packetThresholds
                this.packetThreshold = this.packetThreshold.sort((a, b) => {
                    return a.priority - b.priority;
                });

                const responseVolume = await ThresholdService.getThreshold(volumeUrl);
                this.trafficVolumeThreshold = responseVolume.data?.trafficVolumeThresholds;

                this.trafficVolumeThreshold = this.trafficVolumeThreshold?.sort((a, b) => {
                    return a?.priority - b?.priority;
                });

                if (this.thresholdType == 'CONNECTION' || this.thresholdType == 'PACKET' || this.thresholdType == 'TRAFFIC_VOLUME') {
                    this.varThresholdType = this.thresholdType
                }
            } catch {
                notify('Session expired','error')
                this.logOut()
            }
        },
        thresholdRequest() {
            if (this.setting.type =='CONNECTION') this.addConnectionThreshold();
            if (this.setting.type =='PACKET') this.addPacketThreshold();
            if (this.setting.type =='TRAFFIC_VOLUME') this.addVolumeThreshold();
        },
        async addConnectionThreshold() {
            const connectionUrl = "thresholds?type=CONNECTION";
            const thresholdConfig = {
                trafficType: this.setting.trafficType,
                sourcePorts: this.setting.sourcePorts,
                destinationPrefix: this.setting.destinationPrefix,
                destinationPorts: this.setting.destinationPorts,
                connectionRate: Number(this.setting.connectionRate),
                connectionInterval: Number(this.setting.connectionInterval),
                enabled: this.setting.enabled,
                additionalFilter: this.additionalFilterEnabled ? this.setting.additionalFilter : null,
                timeLimit: Number(this.setting.timeLimit) || 0,
                staticActionIds: this.setting.staticActionIds,
                includeDetectedPortsInActiveRule: this.setting.includeDetectedPortsInActiveRule,
            }
            try{
                const response = await ThresholdService.addThreshold(connectionUrl, thresholdConfig);
                if (response.status == 200 && response.data) {
                    notify('Connection Limit Threshold Added Successfully')
                    this.getThresholds()
                }
            }
            catch(error) {
                var text = 'Connection Limit Threshold addition failed'
                if(error.response.data.message == "Error 1062: Duplicate entry 'UDP' for key 'packet_thresholds.destination_prefix'"){
                    text = text + "due to conflicting Destination Prefix"
                }
                notify(text + ' : ' + error.response?.data?.message , 'error')
            }

        },
        async addPacketThreshold() {
            const connectionUrl = "thresholds?type=PACKET";
            const thresholdConfig = {
                trafficType: this.setting.trafficType,
                minPacketLength: Number(this.setting.minPacketLength),
                maxPacketLength: Number(this.setting.maxPacketLength),
                destinationPrefix: this.setting.destinationPrefix,
                blockPacketLength: this.setting.blockPacketLength,
                packetLengthToBlock:  Number(this.setting.packetLengthToBlock),
                connectionRate: Number(this.setting.connectionRate),
                connectionInterval: Number(this.setting.connectionInterval),
                enabled: this.setting.enabled,
                additionalFilter: this.setting.additionalFilterEnabled ? this.setting.additionalFilter : null,
                timeLimit: Number(this.setting.timeLimit) || 0,
                staticActionIds: this.setting.staticActionIds

            }
            try{
               const response = await ThresholdService.addThreshold(connectionUrl, thresholdConfig);
               if (response.status == 200 && response.data) {
                    notify('Packet Tracking Threshold Added Successfully')
                    this.getThresholds()
                }
            }
            catch(error) {
                var text = 'Packet Tracking Threshold addition failed'
                if(error.response.data.message == "Error 1062: Duplicate entry 'UDP' for key 'packet_thresholds.destination_prefix'"){
                    text = text + "due to conflicting Destination Prefix"
                }
                notify(text + ' : ' + error.response?.data?.message , 'error')
            }
            
            this.setting = initialSettings;
        },
        async addVolumeThreshold() {
            const connectionUrl = "thresholds?type=TRAFFIC_VOLUME";
            const thresholdConfig = {
                trafficType: this.setting.trafficType,
                volume: Number(this.setting.volume),
                volumeInterval: Number(this.setting.volumeInterval),
                destinationPrefix: this.setting.destinationPrefix,
                enabled: this.setting.enabled,
                additionalFilter: this.setting.additionalFilterEnabled ? this.setting.additionalFilter : null,
                timeLimit: Number(this.setting.timeLimit) || 0,
                staticActionIds: this.setting.staticActionIds

            }
            try{
               const response = await ThresholdService.addThreshold(connectionUrl, thresholdConfig);
               if (response.status == 200 && response.data) {
                    notify('Volume Tracking Threshold Added Successfully')
                    this.getThresholds()
                }
            }
            catch(error) {
                var text = 'Volume Tracking Threshold addition failed'
                if(error.response.data.message == "Error 1062: Duplicate entry 'UDP' for key 'packet_thresholds.destination_prefix'"){
                    text = text + "due to conflicting Destination Prefix"
                }
                notify(text + ' : ' + error.response?.data?.message , 'error')
            }
            
            this.setting = initialSettings;

        },

        getDateString(date) {
            return DateTime.fromISO(date).toFormat("yyyy-M-d");
        },
        getFullDateString(date) {
        return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_HUGE);
        },
        async saveSettings(id) {
            const type = this.setting.type;
            const url = `thresholds/${id}?type=${this.setting.type}`;
            var thresholdConfig = {};
            if (type == "PACKET"){
                 thresholdConfig = {
                    trafficType: this.setting.trafficType,
                    minPacketLength: Number(this.setting.minPacketLength),
                    maxPacketLength: Number(this.setting.maxPacketLength),
                    destinationPrefix: this.setting.destinationPrefix,
                    blockPacketLength: this.setting.blockPacketLength,
                    packetLengthToBlock:  Number(this.setting.packetLengthToBlock),
                    connectionRate: Number(this.setting.connectionRate),
                    connectionInterval: Number(this.setting.connectionInterval),
                    enabled: this.setting.enabled,
                    additionalFilter: this.setting.additionalFilterEnabled ? this.setting.additionalFilter : null,
                    timeLimit: Number(this.setting.timeLimit) || 0,
                    staticActionIds: this.setting.staticActionIds,
                }
            }
            if (type == "CONNECTION"){
                thresholdConfig = {
                    trafficType: this.setting.trafficType,
                    minPacketLength: Number(this.setting.minPacketLength),
                    maxPacketLength: Number(this.setting.maxPacketLength),
                    destinationPrefix: this.setting.destinationPrefix,
                    sourcePorts: this.setting.sourcePorts,
                    destinationPorts: this.setting.destinationPorts,
                    connectionRate: Number(this.setting.connectionRate),
                    connectionInterval: Number(this.setting.connectionInterval),
                    enabled: this.setting.enabled,
                    additionalFilter: this.setting.additionalFilterEnabled ? this.setting.additionalFilter : null,
                    timeLimit: Number(this.setting.timeLimit) || 0,
                    staticActionIds: this.setting.staticActionIds,
                    includeDetectedPortsInActiveRule: this.setting.includeDetectedPortsInActiveRule,
                }
            }

            if (type == "TRAFFIC_VOLUME"){
                thresholdConfig = {
                    trafficType: this.setting.trafficType,
                    volume: Number(this.setting.volume),
                    volumeInterval: Number(this.setting.volumeInterval),
                    destinationPrefix: this.setting.destinationPrefix,
                    enabled: this.setting.enabled,
                    additionalFilter: this.setting.additionalFilterEnabled ? this.setting.additionalFilter : null,
                    timeLimit: Number(this.setting.timeLimit) || 0,
                    staticActionIds: this.setting.staticActionIds,
                }
            }

            try{
                const reply = await ThresholdService.editThreshold(url, thresholdConfig);
                if (reply.status == 200 && reply.data) {
                    notify('Threshold updated successfully')
                    this.getThresholds()
                }
            }
            catch(error) {
                var text = 'Threshold update failed'
                if(error.response.data.message == "Error 1062: Duplicate entry 'UDP' for key 'packet_thresholds.destination_prefix'"){
                    text = text + "due to conflicting Destination Prefix"
                }
                notify(text + ' : ' + error.response?.data?.message , 'error')
            }
            this.setting = initialSettings;
        },
    },
};
</script>
<style scoped>
.bg-secondary {
    background-color: #f7fafc !important;
}

.list-group {
    width: 70%;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
