import axios from "axios";
import { config } from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

class ThresholdService {
  getThreshold(url) {
    return axios.get(API_URL + url, {
      headers: authHeader(),
    });
  }
  addThreshold(url, threshold) {
    return axios.post(API_URL + url, threshold, {
      headers: authHeader(),
    });
  }
  editThreshold(url, threshold) {
    return axios.put(API_URL + url, threshold, {
      headers: authHeader(),
    });
  }
  updateThreshold(url, threshold) {
    return axios.put(API_URL + url, threshold, {
      headers: authHeader(),
    });
  }
  deleteThreshold(url) {
    return axios.delete(API_URL + url, {
      headers: authHeader(),
    });
  }
}
export default new ThresholdService();
